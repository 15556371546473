.slogan__Container{
    display:flex;
    justify-content: center;
    align-items: center;
    height:10vh;
    width: 100%;
    background-color: #6C6EA0;
    font-size: 2.7vh;
    color: #FCF7FF;
    border-top: solid 1px #011627;
    border-bottom: solid 1px #011627;
}
