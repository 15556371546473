.signIn__Button{
    text-decoration: none;
    color: black;
    font-size: 1em;
    padding:1em;
    border:none;
    background-color: inherit;
}

.signIn__Button:hover{
    cursor: pointer;
}

.signOut__Container{
    display:flex;
    justify-content: left;
    align-items: center;
}

.signOut__Button{
    text-decoration: none;
    color: black;
    font-size: 1em;
    padding:1em;
    border:none;
    background-color: inherit;
}

.signOut__Button:hover{
    cursor: pointer;
}

.signUp__Button{
    text-decoration: none;
    
    font-size: 1em;
    border:none;
    color: rgb(226, 237, 246);
    background-color: #D5494C;
    padding:1em;
    border-radius: 0.3cm;
}

.signUp__Button:hover{
    cursor: pointer;
    color: rgb(226, 237, 246);
    background-color: #ec8e8f;
}


/* #D5494C  <--rode */
/* rgba(243, 250, 239, 0.4); <-- achtergrond*/
/* #8BB4B6 <-- hele lichte blauwe*/
/* #517B9A <-- blauwe*/
/* #223554 <-- donkerste blauw*/
