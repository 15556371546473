.ExternalLink__Container{
    display: flex;
    justify-content: left;
    white-space: nowrap;
}

.ExternalLink{
    display: flex;
    align-items: center;
    margin-top: 0.3em;

    color: #D5494C;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    text-align: left;
}

.Link{
    text-decoration: none;
    color: #8BB4B6; 
}

.Link:hover{
    color: #D5494C !important; 
}