.ElementBox__Container{
    width: 175px;
    height: 56px;
    position: absolute;
    
}

.ElementBox__MainRoad{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 175px;
    height: 56px;

    color: #517B9A;
    background: #f3f3f3;
    box-shadow: 2px 3px 0px rgba(169, 183, 164, 0.3);
    border-radius: 10px;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    transition: background-color 0.5s ease;
}

.ElementBox__MainRoad__Completed{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 175px;
    height: 56px;

    color: #8BB4B6;
    background: #223554;
    box-shadow: 2px 3px 0px rgba(169, 183, 164, 0.3);
    border-radius: 10px;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
}


.ElementBox__MainRoad:hover{
    color: #8BB4B6;
    background: #223554;
    cursor: pointer;
}

.ElementBox__MainRoad__Completed:hover{
    cursor: pointer;
}

.ElementBox__SideRoad{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 155px;
    height: 50px;

    color: #517B9A;
    background: #FFFFFF;
    box-shadow: 2px 3px 0px rgba(169, 183, 164, 0.3);
    border-radius: 10px;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    transition: background-color 0.5s ease;
}

.ElementBox__SideRoad__Completed{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 155px;
    height: 50px;

    color: #8BB4B6;
    background: #517B9A;
    box-shadow: 2px 3px 0px rgba(169, 183, 164, 0.3);
    border-radius: 10px;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
}


.ElementBox__SideRoad:hover{
    cursor: pointer;
    color: #8BB4B6;
    background: #517B9A;
}

.ElementBox__SideRoad__Completed:hover{
    cursor: pointer;
}

.ElementBox__Start{
    width: 3em;
    height: 3em;
}

.LinkHyperlinks:hover{
    cursor: pointer;
}

.Suggest__Link{
    visibility: hidden;
    color: #8BB4B6;
}

.Suggest__Link:hover{
    color: #D5494C !important; 
}

.ElementBox:hover .Link{
    visibility: visible;
    color: #ec8e8f;
}

.ElementBox:hover .Suggest__Link{
    visibility: visible;
    color: #ec8e8f;
}

.ElementBox:hover{
    cursor: default;
}

/* #D5494C  <--rode */
/* rgba(243, 250, 239, 0.4); <-- achtergrond*/
/* #8BB4B6 <-- hele lichte blauwe*/
/* #517B9A <-- blauwe*/
/* #223554 <-- donkerste blauw*/