@import url('https://fonts.googleapis.com/css2?family=Kavoon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

/* The sidebar menu */
.Container__SideBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    height: 21em; /* Full-height: remove this if you want "auto" height */
    width: 22em; /* Set the width of the sidebar */
    position: absolute; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    left: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    background-color: #ffffff;

    box-shadow: 3px 4px 194px rgba(130, 145, 125, 0.1);
    border-radius: 8px;
}

.Cotma__Title{
    display: flex;
    justify-content: left;
    align-items: center;

    font-family: Kavoon;
    color:#223554;
    font-weight: 400;
    font-size: 2.8em;
}

.Cotma{
    color:#223554;
}

.Roadmaps__Title{
    display: flex;
    justify-content: left;
    align-items: center;
    
    font-family: Inter;
    color:#223554;
    font-weight: 700;
    font-size: 1.8em;
}

.RoadmapNameAndLink{
    font-family: Inter;
    color:#223554;
    font-weight: 500;
    font-size: 1.2em;
    margin-top: 0.5em;
}

.RoadmapNameAndLink:hover{
    cursor: pointer;
    color:#D5494C;
    font-weight: 500;
}

.RoadmapNameAndLink__Selected{
    font-family: Inter;
    color:#D5494C;
    font-weight: 500;
    font-size: 1.2em;
    margin-top: 0.5em;
}

.RoadmapNameAndLink__Selected:hover{
    cursor: pointer;
    color:#D5494C;
    font-weight: 500;
}

.RoadmapName__Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}