.TopBar__Logo{
    width: auto;
    height: 6vh;
}

.curseOfTheMaster__TopLeft__Text { grid-area: CotM__Logo; }
.SearchMap { grid-area: find_subject; }
.TopBar__Options__Auth { grid-area: auth; }


.curseOfTheMaster__TopLeft{
    text-decoration: none;
    color:inherit;
    font-size: 2.4vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 1vw;
}

.curseOfTheMaster__TopLeft__Text{
    text-decoration: none;
    color:inherit;
    font-size: 2.4vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 1vw;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.SearchMap{
    text-decoration: none;
    color: black;
    font-size: 1.8vh;
}

.SearchMap:hover{
    color: #168a7f;
    cursor: pointer;
}