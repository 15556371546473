.card__Container{
    margin:16px;
    height: 300px;
    width: 285px;
    background-color: #ffffff;
    color: #223554;
    border-radius: 0.1cm;
    border: 1px solid rgba(100, 100, 111, 0.2);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: relative;
}

.card__Container:hover{
    cursor:pointer;
    margin:16px;
    height: 300px;
    width: 285px;
    background-color: #ffffff;
    color: #223554;
    border-radius: 0.1cm;
    border: 1px solid rgba(45, 45, 49, 0.2);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    position: relative;
}

.title__Container{
    display:flex;
    flex-direction: column;
    margin-left: 16px;
}

.cardTitle{
    margin-top: 16px;
    font-size: 2em;
}

.cardSubTitle{
    font-size: 1em;
    color: grey;
}

.description__Container{
    margin-top: 30px;
    margin-left: 16px;
    margin-right: 16px;
    font-size: 0.85em;
    color: grey;
}

.icon__Container{
    display: flex;
    position: absolute;
    width: 250px;
    justify-content: space-between;
    bottom: 5px;
    margin-left: 16px;
    margin-right: 16px;
}

.numberOfLikes__InCard{
    display: flex;
    align-items: center;
    justify-content: center;
}

.numberOfViews__InCard{
    display: flex;
    align-items: center;
    justify-content: center;
}

.numberOfContributors__InCard{
    display: flex;
    align-items: center;
    justify-content: center;
}

.FavoriteIcon:hover{
    color:black;
}