@import url(https://fonts.googleapis.com/css2?family=Kavoon&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
/* The sidebar menu */
.Container__SideBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    height: 21em; /* Full-height: remove this if you want "auto" height */
    width: 22em; /* Set the width of the sidebar */
    position: absolute; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    left: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    background-color: #ffffff;

    box-shadow: 3px 4px 194px rgba(130, 145, 125, 0.1);
    border-radius: 8px;
}

.Cotma__Title{
    display: flex;
    justify-content: left;
    align-items: center;

    font-family: Kavoon;
    color:#223554;
    font-weight: 400;
    font-size: 2.8em;
}

.Cotma{
    color:#223554;
}

.Roadmaps__Title{
    display: flex;
    justify-content: left;
    align-items: center;
    
    font-family: Inter;
    color:#223554;
    font-weight: 700;
    font-size: 1.8em;
}

.RoadmapNameAndLink{
    font-family: Inter;
    color:#223554;
    font-weight: 500;
    font-size: 1.2em;
    margin-top: 0.5em;
}

.RoadmapNameAndLink:hover{
    cursor: pointer;
    color:#D5494C;
    font-weight: 500;
}

.RoadmapNameAndLink__Selected{
    font-family: Inter;
    color:#D5494C;
    font-weight: 500;
    font-size: 1.2em;
    margin-top: 0.5em;
}

.RoadmapNameAndLink__Selected:hover{
    cursor: pointer;
    color:#D5494C;
    font-weight: 500;
}

.RoadmapName__Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ExternalLink__Container{
    display: flex;
    justify-content: left;
    white-space: nowrap;
}

.ExternalLink{
    display: flex;
    align-items: center;
    margin-top: 0.3em;

    color: #D5494C;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    text-align: left;
}

.Link{
    text-decoration: none;
    color: #8BB4B6; 
}

.Link:hover{
    color: #D5494C !important; 
}
.ElementBox__Container{
    width: 175px;
    height: 56px;
    position: absolute;
    
}

.ElementBox__MainRoad{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 175px;
    height: 56px;

    color: #517B9A;
    background: #f3f3f3;
    box-shadow: 2px 3px 0px rgba(169, 183, 164, 0.3);
    border-radius: 10px;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    transition: background-color 0.5s ease;
}

.ElementBox__MainRoad__Completed{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 175px;
    height: 56px;

    color: #8BB4B6;
    background: #223554;
    box-shadow: 2px 3px 0px rgba(169, 183, 164, 0.3);
    border-radius: 10px;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
}


.ElementBox__MainRoad:hover{
    color: #8BB4B6;
    background: #223554;
    cursor: pointer;
}

.ElementBox__MainRoad__Completed:hover{
    cursor: pointer;
}

.ElementBox__SideRoad{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 155px;
    height: 50px;

    color: #517B9A;
    background: #FFFFFF;
    box-shadow: 2px 3px 0px rgba(169, 183, 164, 0.3);
    border-radius: 10px;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    transition: background-color 0.5s ease;
}

.ElementBox__SideRoad__Completed{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 155px;
    height: 50px;

    color: #8BB4B6;
    background: #517B9A;
    box-shadow: 2px 3px 0px rgba(169, 183, 164, 0.3);
    border-radius: 10px;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
}


.ElementBox__SideRoad:hover{
    cursor: pointer;
    color: #8BB4B6;
    background: #517B9A;
}

.ElementBox__SideRoad__Completed:hover{
    cursor: pointer;
}

.ElementBox__Start{
    width: 3em;
    height: 3em;
}

.LinkHyperlinks:hover{
    cursor: pointer;
}

.Suggest__Link{
    visibility: hidden;
    color: #8BB4B6;
}

.Suggest__Link:hover{
    color: #D5494C !important; 
}

.ElementBox:hover .Link{
    visibility: visible;
    color: #ec8e8f;
}

.ElementBox:hover .Suggest__Link{
    visibility: visible;
    color: #ec8e8f;
}

.ElementBox:hover{
    cursor: default;
}

/* #D5494C  <--rode */
/* rgba(243, 250, 239, 0.4); <-- achtergrond*/
/* #8BB4B6 <-- hele lichte blauwe*/
/* #517B9A <-- blauwe*/
/* #223554 <-- donkerste blauw*/
.roadmap{
    height:100vh;
    width:100vw;
    position: relative;
}

.Suggest__Skill{
    color: #8BB4B6;
    font-size: 30px;   
}

.Suggest__Skill:hover{
    color: #D5494C;
    cursor:pointer;
}

.Roadmap__Title{
    color: #223554;
    font-size: 30px; 
}

.DevTools{
    position:fixed;
    z-index: 1;
}

.Skill__Level{
    position: absolute;
    margin-left: 7em;
    margin-bottom: 1.4em;
    
    width: 0.5em;
    height: 0.5em;

    border-radius: 50%;
}
/* When you mouse over the navigation links, change their color */

.Container__TopBar{
  width: 99%;
  z-index: 2; /* Stay on top */
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
}

/* Style page content */
.Container__Roadmap{
  margin-left: 0vw; 
}


.SuggestRoadmapInfo__Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #223554;
}

.DescriptionInput__Container{
    margin-top: 1vh;
}

.ConfirmSuggestion__Button{
    margin-top: 3vh;
    text-decoration: none;
    color: white;
    font-size: 1vw;
    border:none;
    background-color: #D5494C;
    padding:1.5vh;
    border-radius: 0.3cm;
}

.ConfirmSuggestion__Button:hover{
    cursor: pointer;
    color: #D5494C;
    background-color: #8BB4B6;
}

.card__Container{
    margin:16px;
    height: 300px;
    width: 285px;
    background-color: #ffffff;
    color: #223554;
    border-radius: 0.1cm;
    border: 1px solid rgba(100, 100, 111, 0.2);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: relative;
}

.card__Container:hover{
    cursor:pointer;
    margin:16px;
    height: 300px;
    width: 285px;
    background-color: #ffffff;
    color: #223554;
    border-radius: 0.1cm;
    border: 1px solid rgba(45, 45, 49, 0.2);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    position: relative;
}

.title__Container{
    display:flex;
    flex-direction: column;
    margin-left: 16px;
}

.cardTitle{
    margin-top: 16px;
    font-size: 2em;
}

.cardSubTitle{
    font-size: 1em;
    color: grey;
}

.description__Container{
    margin-top: 30px;
    margin-left: 16px;
    margin-right: 16px;
    font-size: 0.85em;
    color: grey;
}

.icon__Container{
    display: flex;
    position: absolute;
    width: 250px;
    justify-content: space-between;
    bottom: 5px;
    margin-left: 16px;
    margin-right: 16px;
}

.numberOfLikes__InCard{
    display: flex;
    align-items: center;
    justify-content: center;
}

.numberOfViews__InCard{
    display: flex;
    align-items: center;
    justify-content: center;
}

.numberOfContributors__InCard{
    display: flex;
    align-items: center;
    justify-content: center;
}

.FavoriteIcon:hover{
    color:black;
}
.searchGrid__Container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left:22em;
    width: 60vw;
}

.cardElement__Container{
    margin: 8px;
}

.topBarCategory__Container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.category{
    margin-top:4vh;
    margin-left:2vh;
    margin-right:2vh;
    margin-bottom:1vh;
    color: #517B9A;

    font-size: 20px;
}


.category:hover {
    color: #223554;
    cursor: pointer;
}

.category__selected{
    padding-top:1vh;
    padding-left:1vh;
    padding-right:1vh;
    padding-bottom: 1vh;
    
    margin-top:3vh;
    margin-left:1vh;
    margin-right:1vh;
    margin-bottom: 0vh;

    border-radius: 0.25em;
    color: #D5494C;

    font-size: 20px;
}


.suggestion__Roadmap{
    margin-top:16px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.suggestion__Button{
    color: white;
    font-size: 22px;
    font-weight: 700;
    border:none;
    background-color: #D5494C;
    padding:1.5vh;
    border-radius: 0.3cm;
}

.suggestion__Button:hover{
    color: #D5494C;
    background-color: #8BB4B6;
    cursor: pointer;
}

.signIn__Button{
    text-decoration: none;
    color: black;
    font-size: 1em;
    padding:1em;
    border:none;
    background-color: inherit;
}

.signIn__Button:hover{
    cursor: pointer;
}

.signOut__Container{
    display:flex;
    justify-content: left;
    align-items: center;
}

.signOut__Button{
    text-decoration: none;
    color: black;
    font-size: 1em;
    padding:1em;
    border:none;
    background-color: inherit;
}

.signOut__Button:hover{
    cursor: pointer;
}

.signUp__Button{
    text-decoration: none;
    
    font-size: 1em;
    border:none;
    color: rgb(226, 237, 246);
    background-color: #D5494C;
    padding:1em;
    border-radius: 0.3cm;
}

.signUp__Button:hover{
    cursor: pointer;
    color: rgb(226, 237, 246);
    background-color: #ec8e8f;
}


/* #D5494C  <--rode */
/* rgba(243, 250, 239, 0.4); <-- achtergrond*/
/* #8BB4B6 <-- hele lichte blauwe*/
/* #517B9A <-- blauwe*/
/* #223554 <-- donkerste blauw*/

.TopBar__Logo{
    width: auto;
    height: 6vh;
}

.curseOfTheMaster__TopLeft__Text { grid-area: CotM__Logo; }
.SearchMap { grid-area: find_subject; }
.TopBar__Options__Auth { grid-area: auth; }


.curseOfTheMaster__TopLeft{
    text-decoration: none;
    color:inherit;
    font-size: 2.4vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 1vw;
}

.curseOfTheMaster__TopLeft__Text{
    text-decoration: none;
    color:inherit;
    font-size: 2.4vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 1vw;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.SearchMap{
    text-decoration: none;
    color: black;
    font-size: 1.8vh;
}

.SearchMap:hover{
    color: #168a7f;
    cursor: pointer;
}
.SuggestLinkInfo__Container{
    color: #517B9A;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slogan__Container{
    display:flex;
    justify-content: center;
    align-items: center;
    height:10vh;
    width: 100%;
    background-color: #6C6EA0;
    font-size: 2.7vh;
    color: #FCF7FF;
    border-top: solid 1px #011627;
    border-bottom: solid 1px #011627;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(243, 250, 239, 0.4);
}


/* #D5494C  <--rode */
/* rgba(243, 250, 239, 0.4); <-- achtergrond*/
/* #8BB4B6 <-- hele lichte blauwe*/
/* #517B9A <-- blauwe*/
/* #223554 <-- donkerste blauw*/

/* vvvvvvvvvvvvv COOLERS vvvvvvvvvvvv */

/* https://coolors.co/FCF7FF-dd7230-6c6ea0-011627-2ec4b6 */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a{
  text-decoration: none;
}
