.searchGrid__Container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left:22em;
    width: 60vw;
}

.cardElement__Container{
    margin: 8px;
}

.topBarCategory__Container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.category{
    margin-top:4vh;
    margin-left:2vh;
    margin-right:2vh;
    margin-bottom:1vh;
    color: #517B9A;

    font-size: 20px;
}


.category:hover {
    color: #223554;
    cursor: pointer;
}

.category__selected{
    padding-top:1vh;
    padding-left:1vh;
    padding-right:1vh;
    padding-bottom: 1vh;
    
    margin-top:3vh;
    margin-left:1vh;
    margin-right:1vh;
    margin-bottom: 0vh;

    border-radius: 0.25em;
    color: #D5494C;

    font-size: 20px;
}


.suggestion__Roadmap{
    margin-top:16px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.suggestion__Button{
    color: white;
    font-size: 22px;
    font-weight: 700;
    border:none;
    background-color: #D5494C;
    padding:1.5vh;
    border-radius: 0.3cm;
}

.suggestion__Button:hover{
    color: #D5494C;
    background-color: #8BB4B6;
    cursor: pointer;
}
