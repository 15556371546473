/* When you mouse over the navigation links, change their color */

.Container__TopBar{
  width: 99%;
  z-index: 2; /* Stay on top */
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
}

/* Style page content */
.Container__Roadmap{
  margin-left: 0vw; 
}

