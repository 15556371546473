body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(243, 250, 239, 0.4);
}


/* #D5494C  <--rode */
/* rgba(243, 250, 239, 0.4); <-- achtergrond*/
/* #8BB4B6 <-- hele lichte blauwe*/
/* #517B9A <-- blauwe*/
/* #223554 <-- donkerste blauw*/

/* vvvvvvvvvvvvv COOLERS vvvvvvvvvvvv */

/* https://coolors.co/FCF7FF-dd7230-6c6ea0-011627-2ec4b6 */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a{
  text-decoration: none;
}