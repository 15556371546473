.SuggestRoadmapInfo__Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #223554;
}

.DescriptionInput__Container{
    margin-top: 1vh;
}

.ConfirmSuggestion__Button{
    margin-top: 3vh;
    text-decoration: none;
    color: white;
    font-size: 1vw;
    border:none;
    background-color: #D5494C;
    padding:1.5vh;
    border-radius: 0.3cm;
}

.ConfirmSuggestion__Button:hover{
    cursor: pointer;
    color: #D5494C;
    background-color: #8BB4B6;
}
