.roadmap{
    height:100vh;
    width:100vw;
    position: relative;
}

.Suggest__Skill{
    color: #8BB4B6;
    font-size: 30px;   
}

.Suggest__Skill:hover{
    color: #D5494C;
    cursor:pointer;
}

.Roadmap__Title{
    color: #223554;
    font-size: 30px; 
}

.DevTools{
    position:fixed;
    z-index: 1;
}

.Skill__Level{
    position: absolute;
    margin-left: 7em;
    margin-bottom: 1.4em;
    
    width: 0.5em;
    height: 0.5em;

    border-radius: 50%;
}